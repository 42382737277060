import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useMyContext } from "../context";

// helpers & services
import WeeksList from "../helpers/WeekList";
import { WEEKS_START, REPORTING_DAYS_TYPES } from "../helpers/constants";
import NewService from "../services/NewService";

// components
import WeekSelectorComponent from "../components/WeekSelectorComponent";
import HeaderComponent from "../components/HeaderComponent";
import OfficeTabs from "../components/OfficeTabs"
import LogoutButton from "../components/LogoutButton";

function OfficePage() {
  const weeksList = new WeeksList(WEEKS_START);
  const newService = new NewService();

  const { selectedWeekIndex } = useMyContext();
  const { officeId } = useParams();

  const [officeName, setOfficeName] = useState("");
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [statData, setStatData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [reportDataWe, setReportDataWe] = useState([]);
  const [statDataWe, setStatDataWe] = useState([]);
  const [incomeDataWe, setIncomeDataWe] = useState([]);
  const [expenseDataWe, setExpenseDataWe] = useState([]);
  const [currentReportingDaysTypes, setCurrentReportingDaysTypes] = useState(
    REPORTING_DAYS_TYPES?.weekdays
  );

  useEffect(() => {
    const newWeekPeriod = weeksList.getByIndex(selectedWeekIndex);
    setSelectedWeek(newWeekPeriod);
  }, [selectedWeekIndex]);

  useEffect(() => {
    if(selectedWeek){

      const newTo = new Date(selectedWeek.dateFrom);
      newTo.setDate(newTo.getDate() + 4);

      getDataR(selectedWeek.dateFrom, moment(newTo).format("YYYY-MM-DD"));

      const newFrom = new Date(selectedWeek.dateFrom);
      newFrom.setDate(newFrom.getDate() + 5);

      getDataWe(moment(newFrom).format("YYYY-MM-DD"), selectedWeek.dateTo);
    }
  }, [selectedWeek]);

  const getDataR = useCallback(async (dateFrom, dateTo) => {
    setCurrentReportingDaysTypes(REPORTING_DAYS_TYPES?.weekdays);
    if (selectedWeek) {
      const weData = await newService.getReport(
              officeId,
              dateFrom,
              dateTo,
              false,
      );
      setReportData(weData.data.report);
      setStatData(weData.data.statistics);
      setIncomeData(weData.data.income);
      setExpenseData(weData.data.expense);
      setOfficeName(weData.data.office.name);
    }
  }, [newService, officeId, selectedWeek]);

  const getDataWe = useCallback(async (dateFrom, dateTo) => {
    setCurrentReportingDaysTypes(REPORTING_DAYS_TYPES?.weekends);
    if (selectedWeek) {
      const weekData = await newService.getReport(
          officeId,
          dateFrom,
          dateTo,
          true,
      );
      setReportDataWe(weekData.data.report);
      setStatDataWe(weekData.data.statistics);
      setIncomeDataWe(weekData.data.income);
      setExpenseDataWe(weekData.data.expense);
    }
  }, [newService, officeId, selectedWeek]);

  // Memoize the data before passing it to child components
  const memoizedReportData = useMemo(() => reportData, [reportData]);
  const memoizedStatData = useMemo(() => statData, [statData]);
  const memoizedIncomeData = useMemo(() => incomeData, [incomeData]);
  const memoizedExpenseData = useMemo(() => expenseData, [expenseData]);
  const memoizedReportDataWe = useMemo(() => reportDataWe, [reportDataWe]);
  const memoizedStatDataWe = useMemo(() => statDataWe, [statDataWe]);
  const memoizedIncomeDataWe = useMemo(() => incomeDataWe, [incomeDataWe]);
  const memoizedExpenseDataWe = useMemo(() => expenseDataWe, [expenseDataWe]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between p-2">
          <HeaderComponent />
          <div className="selector-div">
            <WeekSelectorComponent />
          </div>
          <LogoutButton />
        </div>
        <div className="card-body">
          <h3 className="text-center mb-0">{officeName}</h3>
          <OfficeTabs
              setCurrentReportingDaysTypes={setCurrentReportingDaysTypes}
              reportData={memoizedReportData}
              statData={memoizedStatData}
              incomeData={memoizedIncomeData}
              expenseData={memoizedExpenseData}
              reportDataWe={memoizedReportDataWe}
              statDataWe={memoizedStatDataWe}
              incomeDataWe={memoizedIncomeDataWe}
              expenseDataWe={memoizedExpenseDataWe}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default OfficePage;
