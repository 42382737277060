import { memo, useCallback } from "react";
import { toLocal } from "../../helpers/methods";
import moment from "moment";
import useAlert from "../../hooks/useAlert";

import copyIcon from "../../assets/images/copy-icon.svg";

function ReportComponent(props) {
    const [success, error, Alerts] = useAlert(["success", "error"]);

    const handleCopyRow = useCallback((row) => {
        try {
            const copyContent = `${Math.floor(row.total_withdrawals)}\t${Math.floor(row.total_salaries)}\t${Math.floor(row.total_expenses)}`;
            navigator.clipboard
                .writeText(copyContent)
                .then(() => success("Данные строки успешно скопированы!"))
                .catch(() => error("Не удалось скопировать данные."));
        } catch (err) {
            console.error(err);
            error("Произошла ошибка при копировании данных.");
        }
    }, [success, error]);

    const reportDataItems = useCallback(() => {
        if (!props.reportData || props.reportData.length === 0) {
            return (
                <tr>
                    <td colSpan="9" className="text-center" style={{height: "100px", verticalAlign: "middle", border: "none"}}>
                        Нет найдено данных за выбранный период
                    </td>
                </tr>
            );
        }

        let classSalary = "align-right";
        if (props.reportData[0] && Math.abs(props.reportData[0].total_salaries - props.statData[0].total_salary) > 2) {
            classSalary = "align-right-red";
        }
        let classWithdrawals = "align-right";
        if (
            props.reportData[0] &&
            Math.abs(
                props.reportData[0].total_incomes - props.statData[0].crypto - props.reportData[0].total_withdrawals
            ) > props.reportData[0].balance_history
        ) {
            classWithdrawals = "align-right-red";
        }
        let classCash = "align-right";
        if (props.reportData[0] && props.reportData[0].total_expenses !== props.statData[0].cash) {
            classCash = "align-right-red";
        }

        return props.reportData.map((row, index) => (
            <tr key={`report-row-${index + 1}`}>
                <td>
                    <button
                        className="btn p-0"
                        onClick={() => handleCopyRow(row)}
                    >
                        <img src={copyIcon} alt="copy"/>
                    </button>
                </td>
                <td className="align-right">{moment(row.created).format("YYYY-MM-DD HH:mm")}</td>
                <td className="align-right">{toLocal(row.total_incomes)}</td>
                <td className={classWithdrawals}>{toLocal(row.total_withdrawals)}</td>
                <td className={classSalary}>{toLocal(row.total_salaries)}</td>
                <td className={classCash}>{toLocal(row.total_expenses)}</td>
                <td
                    className={
                        row.accounting_income > 0 ? "align-right bold-row" : "align-right-red bold-row"
                    }
                >
                    {toLocal(row.accounting_income)}
                </td>
                <td className="blue-align-right">{toLocal(row.income_prc)}</td>
                <td className="align-right">{toLocal(row.balance_history)}</td>
            </tr>
        ));
    }, [props.reportData, props.statData, handleCopyRow]);

    const statDataItems = useCallback(() => {
        if (props.statData) {
            return props.statData.map((row, index) => {
                return (
                    <tr key={`stat-row-${index + 1}`}>
                        <td className="align-right">{toLocal(row.salary)}</td>
                        <td className="align-right">{toLocal(row.closer)}</td>
                        <td className="align-right">{toLocal(row.total_salary)}</td>
                        <td className="align-right">{toLocal(row.cash)}</td>
                        <td className="align-right">{toLocal(row.crypto)}</td>
                    </tr>
                );
            });
        }
    }, [props.statData]);

    return (
        <div className="card-body overflow-auto">
            <table className="table table-hover">
                <thead id="tableHeader">
                    <tr>
                        <th>&nbsp;</th>
                        <th className="column-min-120">Дата создания</th>
                        <th>Приход</th>
                        <th>Выплата</th>
                        <th>Зарплата</th>
                        <th>Расходы, нал</th>
                        <th>Прибыль</th>
                        <th>%%</th>
                        <th>Остаток</th>
                    </tr>
                </thead>
                <tbody id="content">
                    {reportDataItems()}
                </tbody>
            </table>
            <table className="table table-hover">
                <thead id="tableHeader">
                    <tr key={0}>
                        <th className="onefifth">Зарплата, менеджеры</th>
                        <th className="onefifth">Зарплата, клозеры</th>
                        <th className="onefifth">Зарплата, всего</th>
                        <th className="onefifth">Расходы, нал</th>
                        <th className="onefifth">Расходы, крипто</th>
                    </tr>
                </thead>
                <tbody id="content">
                    {statDataItems()}
                </tbody>
            </table>
            <Alerts/>
        </div>
    );
}

export default memo(ReportComponent);



