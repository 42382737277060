import { useEffect, useState, memo } from "react";

function AlertComponent (props) {
	const [ hidden, setHidden ] = useState(props.hidden);
	const [ children, setChildren ] = useState(props.children);
	useEffect(() => {
		setHidden(props.hidden);
		setChildren(props.children);
	});
	return (
		<div
			className={"alert alert-" + props.style + (hidden ? " d-none" : "")}
			role="alert"
			onClick={() => {
				props.onAlertClickHandler && props.onAlertClickHandler();
			}}
		>
			{children}
		</div>
	);
}

export default memo(AlertComponent);
