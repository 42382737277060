import { memo, useCallback } from "react";
import moment from "moment";
import { toLocal } from "../../helpers/methods";

function TransactionsTableComponent({ transactionsData, transactionCount, transactionTotals }) {
    function truncateString(str, start = 10, end = 8) {
        if (!str || str.length <= start + end) return str;
        return `${str.substring(0, start)}...${str.substring(str.length - end)}`;
    }

    const totalRow = useCallback(() => {
        // Early exit if no transactions or totals
        if (!transactionsData?.length || !transactionTotals) return null;

        return (
            <tr className="bold-row">
                <td>Всего</td>
                <td>&nbsp;</td>
                <td>{transactionTotals.distinct_offices_count}</td>
                <td>{transactionCount}</td>
                <td className="align-right">
                    {toLocal(transactionTotals.total_incoming_friendly_amount)}
                </td>
            </tr>
        );
    }, [transactionsData, transactionCount, transactionTotals]);

    const tableDataItems = useCallback(() => {
        if (!transactionsData) return null;

        if (transactionsData.length === 0) {
            return (
                <tr>
                    <td colSpan="5" className="text-center" style={{ height: "100px", verticalAlign: "middle", border: "none" }}>
                        Не найдено транзакций за выбранный период
                    </td>
                </tr>
            );
        }

        return transactionsData.map((row, index) => (
            <tr key={`transaction-table-row-${index}`}>
                <td>{index + 1}</td>
                <td>{moment(row.created).format('YYYY-MM-DD HH:mm')}</td>
                <td>{row.from_office?.name || ""}</td>
                <td>
                    <div>
                        <a
                            href={`https://tronscan.org/#/address/${row.from_address}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {row.from_wallet?.name || truncateString(row.from_address)}
                        </a>
                        {" => "}
                        <a
                            href={`https://tronscan.org/#/address/${row.to_address}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {row.to_wallet?.name || truncateString(row.to_address)}
                        </a>
                    </div>
                    <div>
                        <span>Transaction ID: </span>
                        <a
                            href={`https://tronscan.org/#/transaction/${row.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {truncateString(row.id)}
                        </a>
                    </div>
                </td>
                <td className="align-right">{toLocal(row.friendly_amount)}</td>
            </tr>
        ));
    }, [transactionsData]);

    return (
        <div className="card-body">
        <table className="table table-hover">
                <thead id="tableHeader">
                <tr>
                    <th className="id-cell">ID</th>
                    <th className="column-min-120">Дата</th>
                    <th>Офис</th>
                    <th>Описание Транзакции</th>
                    <th className="align-right">Сумма</th>
                </tr>
                </thead>
                <tbody id="content">
                    {totalRow()}
                    {tableDataItems()}
                </tbody>
                <tfoot>
                    {totalRow()}
                </tfoot>
            </table>
        </div>
    );
}

export default memo(TransactionsTableComponent);
