import React, { createContext, useState, useContext } from "react";

import { WEEKS_START } from "./helpers/constants";
import WeeksList from "./helpers/WeekList";

const Context = createContext();

export function ContextProvider({ children }) {
  const weeksList = new WeeksList(WEEKS_START);

  const [selectedWeekIndex, setSelectedWeekIndex] = useState(() => {
    const weeks = weeksList.getWeeksList();
    return weeks.length > 1 ? weeks.length - 2 : weeks.length - 1; // Default week - previous
  });

  return (
    <Context.Provider value={{ selectedWeekIndex, setSelectedWeekIndex }}>
      {children}
    </Context.Provider>
  );
}

export function useMyContext() {
  return useContext(Context);
}
