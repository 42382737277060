import { memo } from 'react';
import { Tab, Tabs } from "react-bootstrap";

import NewComponent from "../NewComponent";

function handleTabChange(key){
    console.log("Tab change:", key);
}

function MainTabs(props) {
    const { newWeekData,newWeekTotals, newWeekEndData, newWeekEndTotals } = props;

    return (
        <Tabs
            defaultActiveKey="new"
            onSelect={handleTabChange}
        >
            <Tab
                title="Недельный отчет"
                eventKey={"new"}
            >
                <NewComponent
                    key="table_with_data"
                    tableData={newWeekData}
                    tableTotals={newWeekTotals}
                />
            </Tab>
            <Tab
                eventKey={"weekend"}
                title="Субботний отчет"
            >
                <NewComponent
                    key="table_with_data"
                    tableData={newWeekEndData}
                    tableTotals={newWeekEndTotals}
                />
            </Tab>
        </Tabs>
    );
}

export default memo(MainTabs);