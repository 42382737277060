import { memo, useCallback } from "react";
import { toLocal } from "../../helpers/methods";

function IncomeComponent(props) {

  const totalDataItems = useCallback(() => {
    if (typeof props.statData !== 'undefined') {
      if (props.statData[0]) {
        return (
        <tr className="bold-row">
          <td>&nbsp;</td>
          <td>Всего:</td>
          <td>&nbsp;</td>
          <td className="align-right">{toLocal(props.statData[0].amount)}</td>
          <td className="align-right">{toLocal(props.statData[0].salary)}</td>
          <td className="align-right">{toLocal(props.statData[0].closer)}</td>
        </tr>
        )
      }
    }
  }, [props.statData]);

  const tableDataItems = useCallback(() => {
    if (typeof props.incomeData !== 'undefined' ) {
      return props.incomeData.map((row,index) => {
        return (
          <tr key={`income-table-row-${index}`}>
            <td>{index + 1}</td>
            <td className="column-min-120">{row.date}</td>
            <td>{row.managers}</td>
            <td className="align-right">{toLocal(row.amount/1)}</td>
            <td className="align-right">{toLocal(row.salary/1)}</td>
            <td className="align-right">{toLocal(row.closer/1)}</td>
          </tr>
        )
      })
    }
  }, [props.incomeData]);

  return (
    <div className="card-body overflow-auto">
      <table className="table table-hover">
        <thead id="tableHeader">
          <tr>
            <th className="id-cell">#</th>
            <th className="column-min-120">Дата</th>
            <th>Менеджеры</th>
            <th>Приход</th>
            <th>Зарплата, <br/>менеджеры</th>
            <th>Зарплата, <br/>клоузер</th>
          </tr>
        </thead>
        <tbody id="content">
          {totalDataItems()}
          {tableDataItems()}
        </tbody>
        <tfoot>
          {totalDataItems()}
        </tfoot>
      </table>
    </div>

  );
}

export default memo(IncomeComponent);
