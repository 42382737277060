import AuthService from "../../services/AuthService";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const authService = new AuthService();

function LogoutButton(props) {
    const onLogoutHandler = async () => {
        await authService.logout(); // Perform logout actions
        cookies.remove("user"); // Ensure user cookie is cleared
        window.location.reload(); // Reload to trigger App's user check
    };

    return (
        <button
            className="btn btn-secondary"
            onClick={onLogoutHandler}
            style={{marginLeft: "1rem"}}
        >
            Выйти
        </button>
    );
}

export default LogoutButton;