import axiosInstance from "../axios";

class TransactionsService {

  getTransactions = async (dateFrom, dateTo) => {
    const response = await axiosInstance(
        `/transactions/`,
        {
          params: {
            created_after: dateFrom,
            created_before: dateTo,
            incoming: 1,
            page: 1,
          }
        }
    );
    return { status: true, data: response.data };
  };
}

export default TransactionsService;
