import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";
import { ContextProvider } from "./context";
import { setClearUserState } from "./axios";

// pages
import LoginPage from "./pages/LoginPage";
import OldOfficesPage from "./pages/OldOfficesPage";
import NewOfficesPage from "./pages/NewOfficesPage";
import OfficePage from "./pages/OfficePage";
import TransactionsPage from "./pages/TransactionsPage";

// styles
import "./styles/App.scss";

function App() {
    const cookies = new Cookies();

    const [user, setUser] = useState(
        cookies.get("user") ? cookies.get("user") : null
    );

    useEffect(() => {
        setClearUserState(() => {
            setUser(null); // Clear the user state
        });
    }, []);

    cookies.addChangeListener(function (name, value) {
        console.log(name, value);
        if (name === "user") {
          setUser(value); // Clears user when cookie is removed
        }
    });

    return (
      <ContextProvider>
          <Router>
              {user ? (
                  <React.Fragment>
                      <div className="container-xxl">
                          <Routes>
                              <Route path="/" element={<NewOfficesPage />} />
                              <Route path="/old-offices/" element={<OldOfficesPage />} />
                              <Route path="/office/:officeId" element={<OfficePage />} />
                              <Route path="/transactions/" element={<TransactionsPage />} />
                          </Routes>
                      </div>
                  </React.Fragment>
              ) : (
                  <LoginPage />
              )}
          </Router>
      </ContextProvider>
    );
}

export default App;
