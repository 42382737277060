import axiosInstance from "../axios";

class NewService {

  getReport = async (officeId, dateFrom, dateTo, isWeekends = false) => {
    const response = await axiosInstance(
            `/offices/${officeId}`,
            {
              params: {
                start_date: dateFrom,
                end_date: dateTo,
                page: 1,
                weekend: isWeekends ? 1 : 0
            }
          }
    );
    return { status: true, data: response.data };
  };

  getOfficesList = async (dateFrom, dateTo, isWeekends = false, isNew = true) => {
    const response = await axiosInstance(
      `/offices`,
      {
        params: {
          start_date: dateFrom,
          end_date: dateTo,
          page: 1,
          weekend: isWeekends ? 1 : 0,
          is_new: isNew ? 1 : 0
        },
      }
    );
    return { status: true, data: response.data };
  };
}

export default NewService;
