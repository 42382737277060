import Cookies from "universal-cookie";
global.Buffer = global.Buffer || require("buffer/").Buffer;

const axios = require("axios");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
  withCredentials: true,
  xsrfHeaderName: 'x-csrftoken',
  xsrfCookieName:'csrftoken',
});
const cookies = new Cookies();

// Global function to clear user state
let clearUserState = null;

export const setClearUserState = (clearUser) => {
  clearUserState = clearUser;
};

let lastRefresh = 0;// NOTE! The interceptor refreshes token if about to expire
axiosInstance.interceptors.response.use(async function (response) {
  const token = cookies.get('jwt-auth');
  if (!token) return response;
  const jwtData = JSON.parse(Buffer.from(token.split(".")[1], "base64"));
  const tokenCreationStamp = jwtData.iat * 1000;
  const tokenExpiresIn = jwtData.exp * 1000;

  const now = Date.now();
  if (now - lastRefresh < tokenExpiresIn / 2) return response;
  const timeLeft = tokenExpiresIn - (now - tokenCreationStamp);

  if (timeLeft < 0) {
    window.location.reload();
  } else if (timeLeft < tokenExpiresIn / 4) {
    lastRefresh = now;
    const res = await axiosInstance.post(
            'token/refresh/',
            {refresh: cookies.get('jwt-refresh')},
    );

    if (res.status !== 200) {
      console.log("Failed refresh!!");
    }
  }
    return response;
  },
    function (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized! Clearing user state...");
        cookies.remove("jwt-auth");
        cookies.remove("jwt-refresh");

        // Clear the user state, triggering the app to render the LoginPage
        if (clearUserState) {
          clearUserState();
        }
      }
      return Promise.reject(error);
    }
);

axios.interceptors.response.use((request) => {
  const token = request.headers[axios.defaults.xsrfHeaderName];

  if (!!token) {
    Cookies.set(`${axios.defaults.xsrfCookieName}`, token, {
      expires: 365,
      sameSite: 'None',
      secure: true,
    });
  }

  return request;
});

// Needed for CORS requests, only for our base url
axios.interceptors.request.use((request) => {
  if (!request.url.includes('http')) {
    request.withCredentials = true;
    request.crossdomain = true;
    request.withXSRFToken = true;
  }
  return request;
});

export default axiosInstance;
