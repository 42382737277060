import { NavLink } from "react-router-dom";
import { memo } from "react";

function HeaderComponent() {

	return (
		<div className="home-link">
			<p className="m-0">Офисы: </p>
			<NavLink
				to="/"
				className={({ isActive }) =>
					`mx-2 ${isActive && "fw-bold"}`
				}
			>
				Новые
			</NavLink>
			<NavLink
				to="/old-offices/"
				className={({ isActive }) =>
					`mx-2 ${isActive && "fw-bold"}`
				}
			>
				Старые
			</NavLink>
			<p className="mx-2 mb-0">|</p>
			<NavLink
				to="/transactions"
				className={({ isActive }) =>
					`mx-2 ${isActive && "fw-bold"}`
				}
			>
				Транзакции
			</NavLink>
		</div>
	);
}

export default memo(HeaderComponent);
