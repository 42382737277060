import React, { useState, useEffect, useRef, useMemo } from "react";
import { useMyContext } from "../../context";
import { format, startOfISOWeek, endOfISOWeek } from "date-fns";
import { ru } from "date-fns/locale";
import { DayPicker, isDateInRange } from "react-day-picker";
import "react-day-picker/style.css";
import { WEEKS_START } from "../../helpers/constants";
import WeeksList from "../../helpers/WeekList";

function WeekSelectorComponent() {
    const { selectedWeekIndex, setSelectedWeekIndex } = useMyContext();
    const weeksList = useMemo(() => new WeeksList(WEEKS_START), []);

    const [selectedDate, setSelectedDate] = useState(() => {
        const initialWeek = weeksList.getByIndex(selectedWeekIndex);
        return initialWeek ? new Date(initialWeek.dateFrom) : new Date();
    });

    const [weekRange, setWeekRange] = useState("");
    const [selectedWeek, setSelectedWeek] = useState(() => {
        const initialWeek = weeksList.getByIndex(selectedWeekIndex);
        if (initialWeek) {
            return { from: new Date(initialWeek.dateFrom), to: new Date(initialWeek.dateTo) };
        }
        const today = new Date();
        return { from: startOfISOWeek(today), to: endOfISOWeek(today) };
    });

    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const calendarRef = useRef(null);

    // Calculate the current week's range
    const today = new Date();
    const currentWeekStart = startOfISOWeek(today);
    const currentWeekEnd = endOfISOWeek(today);

    // Update weekRange and selectedDate based on context's selectedWeekIndex
    useEffect(() => {
        const selectedWeek = weeksList.getByIndex(selectedWeekIndex);
        if (selectedWeek) {
            const start = new Date(selectedWeek.dateFrom);
            const end = new Date(selectedWeek.dateTo);
            setSelectedDate(start);
            setWeekRange(`${format(start, "yyyy-MM-dd")} - ${format(end, "yyyy-MM-dd")}`);
            setSelectedWeek({ from: start, to: end });
        }
    }, [selectedWeekIndex, weeksList]);

    // Handle day selection to calculate week range and update context
    const handleDayClick = (date) => {
        if (!date) return;

        const weekStart = startOfISOWeek(date);
        const weekEnd = endOfISOWeek(date);

        if (weekStart > currentWeekEnd) {
            // Prevent selecting weeks beyond the current week's range
            return;
        }

        setSelectedDate(weekStart);
        setWeekRange(`${format(weekStart, "yyyy-MM-dd")} - ${format(weekEnd, "yyyy-MM-dd")}`);
        setSelectedWeek({ from: weekStart, to: weekEnd });

        // Only update context if the selected week is different
        const selectedIndex = weeksList.getWeeksList().findIndex(
            (week) => week.dateFrom === format(weekStart, "yyyy-MM-dd")
        );
        if (selectedIndex !== selectedWeekIndex) {
            setSelectedWeekIndex(selectedIndex);
        }

        setIsCalendarVisible(false); // Hide the calendar after selection
    };

    // Close the calendar when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setIsCalendarVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className="d-flex flex-column align-items-center position-relative">
            <div className="d-flex align-items-center">
                <label className="mx-2" style={{ whiteSpace: "nowrap" }}>Ведомость за</label>

                <input
                    type="text"
                    className="form-control"
                    value={weekRange}
                    onClick={() => setIsCalendarVisible((prev) => !prev)} // Toggle calendar visibility
                    style={{
                        cursor: "pointer",
                        width: "220px",
                        maxWidth: "100%",
                        backgroundColor: "#fff",
                    }}
                    readOnly
                />

                {isCalendarVisible && (
                    <div
                        className="weekPicker-wrapper"
                        ref={calendarRef}
                        style={{
                            position: "absolute",
                            zIndex: 999,
                            top: "calc(100% + 10px)",
                            right: "0",
                            backgroundColor: "white",
                            padding: "4px",
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.4)",
                            borderRadius: "0.25rem",
                        }}
                        onClick={(e) => e.stopPropagation()} // Ensure calendar events propagate correctly
                    >
                        <DayPicker
                            mode="single"
                            selected={selectedDate}
                            onSelect={handleDayClick}
                            defaultMonth={selectedDate}
                            showWeekNumber={false}
                            locale={ru}
                            showOutsideDays
                            captionLayout="dropdown" // Enables month and year dropdowns
                            startMonth={new Date(WEEKS_START)} // Start of selectable range
                            endMonth={new Date(today.getFullYear(), today.getMonth())} // End of selectable range
                            hidden={[
                                { before: new Date(WEEKS_START) }, // Hide dates before start date
                                { after: currentWeekEnd },         // Hide dates after the current week
                            ]}
                            modifiers={{
                                selected: selectedWeek,
                                range_start: selectedWeek.from,
                                range_end: selectedWeek.to,
                                range_middle: (date) =>
                                    selectedWeek ? isDateInRange(date, selectedWeek, { excludeEnds: true }) : false,
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default WeekSelectorComponent;











