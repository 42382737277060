import axiosInstance from "../axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class AuthService {
	login = (username, password) => {
		const data = new URLSearchParams();
		data.append("email", username);
		data.append("password", password);

		return axiosInstance.post(`/auth/login/`, data);
	};

	logout = async () => {
		try {
			await axiosInstance.post("/auth/logout", {}); // Make API call for server-side logout
		} catch (e) {
			console.error("Logout API call failed", e);
		}
		cookies.remove("jwt-auth"); // Remove authentication token
		cookies.remove("jwt-refresh"); // Remove refresh token
		cookies.remove("user"); // Remove user information
	};

	getUserInformation = () => axiosInstance.get("/auth/user/");
}

export default AuthService;
