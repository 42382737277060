import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import moment from "moment";

// helpers & services
import TransactionsService from "../services/TransactionsService";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { startOfMonth, endOfMonth } from "date-fns";

// components
import TransactionsTableComponent from "../components/TransactionsTableComponent";
import HeaderComponent from "../components/HeaderComponent";
import LogoutButton from "../components/LogoutButton";
import { ru } from "date-fns/locale";

function TransactionsPage() {
    const transactionsService = new TransactionsService();

    const [dateFrom, setDateFrom] = useState(() => startOfMonth(new Date()));
    const [dateTo, setDateTo] = useState(() => endOfMonth(new Date()));

    const [transactionsData, setTransactionsData] = useState([]);
    const [transactionsCount, setTransactionsCount] = useState(0);
    const [transactionsTotals, setTransactionTotals] = useState({});
    const [isCalendarFromVisible, setIsCalendarFromVisible] = useState(false);
    const [isCalendarToVisible, setIsCalendarToVisible] = useState(false);

    const calendarFromRef = useRef(null);
    const calendarToRef = useRef(null);

    useEffect(() => {
        getTransactionsData(moment(dateFrom).format("YYYY-MM-DD"), moment(dateTo).format("YYYY-MM-DD"));
    }, [dateFrom, dateTo]);

    const getTransactionsData = useCallback(async (from, to) => {
        const transactions = await transactionsService.getTransactions(from, to);

        // Separate count, totals, and results from the response
        const { count, totals, results } = transactions.data;

        // Update state
        setTransactionsData(results); // Set table rows
        setTransactionsCount(count); // Set total count
        setTransactionTotals(totals); // Set totals object
    }, [transactionsService]);

    const handleDateChange = (date, isFrom) => {
        if (!date) return; // Ignore invalid dates

        if (isFrom) {
            if (moment(date).isSame(dateFrom, 'day')) return; // Avoid resetting the same date
            setDateFrom(date);
            if (date > dateTo) {
                setDateTo(date);
            }
        } else {
            if (moment(date).isSame(dateTo, 'day')) return; // Avoid resetting the same date
            setDateTo(date);
            if (date < dateFrom) {
                setDateFrom(date);
            }
        }
    };

    const toggleCalendarVisibility = (isFrom) => {
        if (isFrom) {
            setIsCalendarFromVisible((prev) => !prev);
        } else {
            setIsCalendarToVisible((prev) => !prev);
        }
    };

    const changeMonth = useCallback((direction) => {
        setDateFrom((prev) => {
            const current = prev || new Date(); // Fallback to today if prev is null
            return direction === "prev"
                ? startOfMonth(new Date(current.getFullYear(), current.getMonth() - 1))
                : startOfMonth(new Date(current.getFullYear(), current.getMonth() + 1));
        });

        setDateTo((prev) => {
            const current = prev || new Date(); // Fallback to today if prev is null
            return direction === "prev"
                ? endOfMonth(new Date(current.getFullYear(), current.getMonth() - 1))
                : endOfMonth(new Date(current.getFullYear(), current.getMonth() + 1));
        });
    }, []);

    const handleClickOutside = (event) => {
        if (calendarFromRef.current && !calendarFromRef.current.contains(event.target)) {
            setIsCalendarFromVisible(false);
        }
        if (calendarToRef.current && !calendarToRef.current.contains(event.target)) {
            setIsCalendarToVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const memoizedTransactionsData = useMemo(() => transactionsData, [transactionsData]);
    const memoizedTransactionsTotals = useMemo(() => transactionsTotals, [transactionsTotals]);

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between p-2">
                    <HeaderComponent />
                    <div className="date-picker-container d-flex align-items-center position-relative">
                        <button
                            className="btn btn-md btn-primary me-1 pt-1 pb-1 m-0 mx-2"
                            onClick={() => changeMonth("prev")}
                        >
                            -
                        </button>
                        <div className="position-relative mx-2" ref={calendarFromRef}>
                            <input
                                type="text"
                                className="form-control"
                                value={moment(dateFrom).format("YYYY-MM-DD")}
                                onClick={() => toggleCalendarVisibility(true)}
                                readOnly
                                style={{
                                    cursor: "pointer",
                                    width: "160px",
                                    maxWidth: "100%",
                                    backgroundColor: "#fff",
                                }}
                            />
                            {isCalendarFromVisible && (
                                <div
                                    className="dayPicker-wrapper"
                                    style={{
                                        position: "absolute",
                                        zIndex: 999,
                                        top: "calc(100% + 10px)",
                                        right: "0",
                                        backgroundColor: "white",
                                        padding: "4px",
                                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.4)",
                                        borderRadius: "0.25rem",
                                    }}
                                >
                                    <DayPicker
                                        mode="single"
                                        selected={dateFrom}
                                        onSelect={(date) => handleDateChange(date, true)}
                                        defaultMonth={dateFrom}
                                        showOutsideDays
                                        locale={ru}
                                    />
                                </div>
                            )}
                        </div>
                        <span className="mx-2"> => </span>
                        <div className="position-relative mx-2" ref={calendarToRef}>
                            <input
                                type="text"
                                className="form-control"
                                value={moment(dateTo).format("YYYY-MM-DD")}
                                onClick={() => toggleCalendarVisibility(false)}
                                readOnly
                                style={{
                                    cursor: "pointer",
                                    width: "160px",
                                    maxWidth: "100%",
                                    backgroundColor: "#fff",
                                }}
                            />
                            {isCalendarToVisible && (
                                <div
                                    className="dayPicker-wrapper"
                                    style={{
                                        position: "absolute",
                                        zIndex: 999,
                                        top: "calc(100% + 10px)",
                                        right: "0",
                                        backgroundColor: "white",
                                        padding: "4px",
                                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.4)",
                                        borderRadius: "0.25rem",
                                    }}
                                >
                                    <DayPicker
                                        mode="single"
                                        selected={dateTo}
                                        onSelect={(date) => handleDateChange(date, false)}
                                        defaultMonth={dateTo}
                                        showOutsideDays
                                        locale={ru}
                                    />
                                </div>
                            )}
                        </div>
                        <button
                            className="btn btn-md btn-primary me-1 pt-1 pb-1 m-0 mx-2"
                            onClick={() => changeMonth("next")}
                        >
                            +
                        </button>
                    </div>
                    <LogoutButton/>
                </div>
                <TransactionsTableComponent
                    transactionsData={memoizedTransactionsData}
                    transactionCount={transactionsCount}
                    transactionTotals={memoizedTransactionsTotals}
                />
            </div>
        </React.Fragment>
    );
}

export default TransactionsPage;

