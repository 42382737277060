import { memo } from 'react';
import { Tab, Tabs } from "react-bootstrap";

// components
import ReportComponent from "../ReportComponent";
import IncomeComponent from "../IncomeComponent";
import ExpenseComponent from "../ExpenseComponent";

function OfficeTabs(props) {
    const {
        setCurrentReportingDaysTypes,
        reportData,
        statData,
        incomeData,
        expenseData,
        reportDataWe,
        statDataWe,
        incomeDataWe,
        expenseDataWe,
    } = props;

    return (
        <Tabs
            defaultActiveKey="weekdays"
            onSelect={setCurrentReportingDaysTypes}
        >
            <Tab
                eventKey="weekdays"
                title="Недельный отчет"
            >
                <ReportComponent
                    key="report_header"
                    reportData={reportData}
                    statData={statData}
                />
                <Tabs>
                    <Tab eventKey="income" title="Приход">
                        <IncomeComponent
                            key="report_header"
                            incomeData={incomeData}
                            statData={statData}
                        />
                    </Tab>
                    <Tab eventKey="expense" title="Расход">
                        <ExpenseComponent
                            key="report_header"
                            expenseData={expenseData}
                            statData={statData}
                        />
                    </Tab>
                </Tabs>
            </Tab>
            <Tab
                eventKey="weekend"
                title="Субботний отчет"
            >
                <ReportComponent
                    key="report_header"
                    reportData={reportDataWe}
                    statData={statDataWe}
                />
                <Tabs>
                    <Tab eventKey="income" title="Приход">
                        <IncomeComponent
                            key="report_header"
                            incomeData={incomeDataWe}
                            statData={statDataWe}
                        />
                    </Tab>
                    <Tab eventKey="expense" title="Расход">
                        <ExpenseComponent
                            key="report_header"
                            expenseData={expenseDataWe}
                            statData={statDataWe}
                        />
                    </Tab>
                </Tabs>
            </Tab>
        </Tabs>
    );
}

export default memo(OfficeTabs);